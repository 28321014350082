"use client";

import React from 'react';
import dynamic from 'next/dynamic';
import { BadgeTitle } from "../badge-title";
import { TitleWithBadge } from "../title-with-badge";
import { WandSparkles } from "lucide-react";
import { useTranslations } from 'next-intl';

const PlyrVideo = dynamic(() => import('./plyr-video').then(mod => mod.PlyrVideo), { ssr: false });

export function LinkedinVideo() {
  const t = useTranslations('home.linkedinVideo');

  return (
    <div className="container relative lg:max-w-[1344px]">
      <div className="relative">
        <video
          autoPlay
          loop
          muted
          playsInline
          preload="false"
          className="inset-0 w-full h-full object-cover -z-10"
          style={{
            WebkitMaskImage: "radial-gradient(#fff, #000)",
            maskImage: "radial-gradient(#fff, #000)",
          }}
        >
          <source src="/sales-copilot/linkedinvideo.webm" type="video/webm" />
          <track srcLang="en" label="English" />
          Your browser does not support the video tag.
        </video>
        <div className="relative z-10">
          <TitleWithBadge
            title={t('title')}
            badgeText={t('badgeText')}
            badgeIcon={WandSparkles}
            description={t('description')}
          />
        </div>
        <div className="mt-8">
          <PlyrVideo 
          videoUrl={t('videoUrl')} 
   
          />
        </div>
      </div>
    </div>
  );
}