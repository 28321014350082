import React from 'react';
import {BadgeTitle} from './badge-title';
import { cn } from '@/lib/utils';

interface TitleWithBadgeProps {
    title?: string;
    badgeText?: string;
    badgeIcon?: React.ElementType;
    description?: string;
    className?: string;
}

export const TitleWithBadge = ({ title, badgeText, badgeIcon, description, className } : TitleWithBadgeProps) => {
  return (
    <div className={cn(className, "md:mb-18 relative z-10 mx-auto mb-12 flex max-w-5xl flex-col items-center justify-center text-center",className)}>
      {badgeText && (
        <BadgeTitle text={badgeText} Icon={badgeIcon} className="pt-0 lg:pt-0" />
      )}
      <h2 className="font-book font-styling font-display font-effect-hero font-gradient font-base bg-gradient-to-br from-[black] to-primary-600 bg-clip-text text-center text-3xl uppercase leading-[1.2] tracking-[.2em] text-transparent md:text-4xl md:leading-[1.5]">
        {title}
      </h2>
      <p className="mt-4 leading-tight text-neutral-800 [text-wrap:balance] lg:mt-2 lg:text-lg lg:text-gray-600 max-w-4xl">
        {description}
      </p>
    </div>
  );
};

