import React from "react";
import { type ReactElement } from 'react';
// import { LucideIcon } from "@/components/icons";
// import dynamicIconImports from 'lucide-react/dynamicIconImports';


interface TileQuoteProps {
    icon?: string | ReactElement ;
    quote: string;
}

function TileQuote({  icon, quote }: TileQuoteProps) {

    let modifiedIcon = icon;

    // Check if icon is a React element and clone it with new props
    if (icon && typeof icon !== "string") {
        modifiedIcon = React.cloneElement(icon, {
            className: "w-9 h-9 lg:w-12 lg:h-12",
        });
    }


  return (

    <div className="px-11 flex h-full w-full flex-col justify-center self-start pb-36 relative">
      <div className="z-[-1] my-3.5 -ml-3.5 w-32">
        <div className="relative  flex size-16 items-center justify-center rounded-full bg-black bg-opacity-20 text-contrast backdrop-blur-md lg:size-24">
        {icon && 
          typeof icon === "string" ? (
            // <LucideIcon className="w-12 h-12" name={icon as keyof typeof dynamicIconImports} />
            <></>
          )
          : modifiedIcon
        }
        </div>    
      </div>
      <div className="quote ltr w-full max-w-[500px] text-left antialiased">
        <p className="max-w-full text-4xl font-semibold leading-tight tracking-tighter text-contrast md:text-5xl xl:text-6xl">{quote}</p>
      </div>
    </div>

  );
}

export default TileQuote;
