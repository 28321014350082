import './shimmer-button.css'
import { cn } from "@/lib/utils"
interface ShimmerButtonProps {
    className?: string;
}


export function ShimmerButton({ className = '' }: ShimmerButtonProps) {
    return (
            <button className={cn("shimmer-button", className)}>
                <span className="shimmer-button-text">Bulk send</span>
                <span className="shimmer"></span>
            </button>

    )
}
