"use client";

// import { useTranslations } from "next-intl";
import Spotlight from "@/components/marketing/spotlight";
import { SpotlightCard } from "@/components/marketing/spotlight";
import TileImage from "@/components/marketing/tile-image";
import TileQuote from "./tile-quote";
import { cn } from "@/lib/utils";
import React from "react";

import Image from "next/image";

type GridProps = {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  children?: React.ReactNode;
  spotlight?: boolean;
  style?: React.CSSProperties;
};

export const Grid: React.FC<GridProps> = ({ xs, sm, md, lg, children, spotlight, style }) => {
  // const defaultSpan = lg || md || sm || xs || 12;
    const defaultSpan = 12;
    // Define a mapping function to convert prop values to Tailwind's static class names
    const getClassNames = (xs: number | undefined, sm: number | undefined, md: number | undefined, lg: number | undefined, defaultSpan: number) => {
      // POSIBLE VALUES 4 6 8 12
      const breakpoints = {
        xs: xs === 4 ? `xs:col-span-4` : xs === 6 ? `xs:col-span-6` : xs === 8 ? `xs:col-span-8` : xs === 12 ? `xs:col-span-12` : "",
        sm: sm === 4 ? `sm:col-span-4` : sm === 6 ? `sm:col-span-6` : sm === 8 ? `sm:col-span-8` : sm === 12 ? `sm:col-span-12` : "",
        md: md === 4 ? `md:col-span-4` : md === 6 ? `md:col-span-6` : md === 8 ? `md:col-span-8` : md === 12 ? `md:col-span-12` : "",
        lg: lg === 4 ? `lg:col-span-4` : lg === 6 ? `lg:col-span-6` : lg === 8 ? `lg:col-span-8` : lg === 12 ? `lg:col-span-12` : "",
    
        // sm: sm ? `sm:col-span-6` : "",
        // md: md ? `md:col-span-8` : "",
        // lg: lg ? `lg:col-span-12` : "",
      };
  
      return `w-full ${
        breakpoints.xs
      } ${breakpoints.sm} ${breakpoints.md} ${
        breakpoints.lg
      } col-span-12`;
    };
  
    // Use the mapping function to get the class names
    const classString = getClassNames(xs, sm, md, lg, defaultSpan);

    const Container = spotlight ? SpotlightCard : 'div';
  
  return (
    <Container
    // className={`${classString} ${!spotlight && "rounded-[23px]"} shadow-[0_60px_60px_rgba(0,0,0,0.02),0_41.7776px_33.4221px_rgba(0,0,0,0.02),0_22.3363px_17.869px_rgba(0,0,0,0.03),0_12.5216px_10.0172px_rgba(0,0,0,0.03),0_6.6501px_5.32008px_rgba(0,0,0,0.04),0_2.76726px_2.21381px_rgba(0,0,0,0.07)]`}
    className={`${classString} ${!spotlight && "rounded-[23px]"}`}
   style={style}
   >
      <div className="relative h-full w-full bg-contrast rounded-[23px] flex z-20 overflow-hidden">
        <div
          className="absolute bottom-0 translate-y-1/2 left-1/2 -translate-x-1/2 pointer-events-none -z-10 w-1/2 aspect-square"
          aria-hidden="true"
        >
          <div className="absolute inset-0 translate-z-0 bg-slate-200 rounded-full blur-[80px]"></div>
        </div>
        {children}
      </div>
    </Container>
  );
};

type TileHeadlineProps = {
  children: React.ReactNode;
  allFontsWhite?: boolean;
  titleColor?: string; // Assuming this is a color like 'red-500', 'blue-700' etc.
  style?: React.CSSProperties; // Define it as optional if it might not exist on all blocks
};

const TileHeadline: React.FC<TileHeadlineProps> = ({
  children,
  allFontsWhite,
  titleColor,
  style
}) => {
  // You can handle dynamic classes or styles here
  let textColor = "text-[#5761e6]"; // Default color
  if (allFontsWhite) {
    textColor = "text-white";
  } else if (titleColor) {
    textColor = `${titleColor}`; // This assumes titleColor is a valid Tailwind color
  }

  return (
    <h3
    style={style}
      className={`uppercase block font-semibold tracking-widest leading-tight ${textColor} text-xl lg:text-2xl`}
    >
      {children}
    </h3>
  );
};

type ImagePosition = {
  vertical?: "top" | "center" | "bottom" | "stretch";
  horizontal?: "left" | "center" | "right" | "stretch";
};

type BadgeProps = {
  text: string;
  icon?: string | React.ReactElement;
};

export interface TileBlockProps {
  allFontsWhite?: boolean;
  backgroundImage?: string | React.ReactElement;
  backgroundImageClassname?: string;
  background?: string;
  className?: string;
  description?:  string | React.ReactElement;
  descriptionStyle?: React.CSSProperties;
  icon?: string | React.ReactElement;
  imagePosition?: ImagePosition;
  image?: string | React.ReactElement;
  imageClassname?: string;
  imageStyle?: React.CSSProperties;
  mobileImage?: string | React.ReactElement;
  quote?: string;
  style?: React.CSSProperties; // Define it as optional if it might not exist on all blocks
  titleColor?: string;
  title?: string;
  titleStyle?: React.CSSProperties;
  variant?: string;
  withBoxShadow?: boolean;
  badge?: BadgeProps;
  // Add any other props that are used but not explicitly mentioned here
}

export const TileBlock: React.FC<TileBlockProps> = (props) => {
  const {
    allFontsWhite,
    badge,
    backgroundImage,
    backgroundImageClassname,
    background,
    className,
    description,
    descriptionStyle,
    icon,
    imagePosition,
    image,
    imageClassname,
    mobileImage,
    quote,
    style,
    titleColor,
    title,
    titleStyle,
    variant,
    withBoxShadow,
    ...others
  } = props;

  const isMobile = false;
 
  
  let badgeIcon

  if(badge?.icon) {
    if (typeof badge.icon !== "string") { // Check if badge.icon is a React element 
      badgeIcon = React.cloneElement(badge.icon, {
          className: "w-4 h-4",
      });
  }
  }

  
  return (
    <>
      {/* <div
      className={`w-full rounded-lg bg-white border border-[color-variable] shadow-xl overflow-hidden flex m-auto box-border h-full relative ${
        background ? `bg-[${background}]` : ""
      } ${allFontsWhite ? "text-white" : ""}`}
    > */}

      <div 
      className="absolute w-full h-full" 
      
    >
    
        {backgroundImage && 
           typeof backgroundImage === "string" ? (
            <Image

              //className="transform -translate-x-1/2 translate-y-[55%] scale-[2.5] sm:translate-x-[30%] sm:-translate-y-[40%] sm:scale-200 md:translate-x-[20%] md:-translate-y-[10%] md:scale-140 lg:transform-none"
              // className="transform -translate-x-1/2 "
              className={cn(`${quote ? "brightness-[0.6]":""} object-right-bottom`, backgroundImageClassname)}
              src={backgroundImage}
              alt="Background Image"
              fill
              loading='eager'
              quality={75}

              style={
                {
                  objectFit: "cover",
            
      
                }
              }

            />

            
           ) :
            backgroundImage
        
        }
      </div> 
      <div className="translate-z-0 box-border flex size-full flex-1 flex-col p-0 z-10">
  
        <div

          className="px-5 pt-5 notmobile:px-7 notmobile:pt-7"
        >
               {badge && <div className="mb-5 flex w-max items-center gap-1.5 rounded-full px-3 py-1 text-sm font-medium leading-5 shadow-[0_0_0_1px_#2c405e0f,0_1px_1px_#2c405e0a,0_2px_4px_#2c405e14]">
          {badge.icon && badgeIcon}
          {badge.text}
        </div>}
          <TileHeadline 
          style={titleStyle}
            
            allFontsWhite={allFontsWhite} titleColor={titleColor}>
            {title && title}
          </TileHeadline>
          <div
            style={descriptionStyle}
            className={cn(
              "mt-2.5", // margin-top: 0.7em approximated to closest Tailwind value
              "text-lg ", // font-size: 21px default, maps to 'text-lg'
              "leading-normal", // line-height: 1.4211026316 approximated
              "font-normal",
              {
                "text-muted-foreground": !allFontsWhite, // Using theme or hardcoded color
                "text-white opacity-80": allFontsWhite, // Custom white with opacity
              }
            )}
          >
            {description}
          </div>
        </div>
        {/* {variant === "image" ? image && <TileImage image={image} imagePosition={imagePosition} withBoxShadow={withBoxShadow} /> : <TileBlockquote icon={icon} quote={quote} />} */}
        {variant === "image" ? (
          image && (
            <TileImage
              image={image}
              imageClassname={imageClassname}
              mobileImage={mobileImage}
              imagePosition={imagePosition}
              withBoxShadow={withBoxShadow}
            />
          )
        ) : (
          quote && <TileQuote icon={icon} quote={quote}/>
        )}
      </div>

      {/* </div> */}
    </>
  );
};
