'use client'
import React, {useState, useEffect} from 'react';
import Image from "next/image";
import { useIsMobile } from '@/hooks';
import { cn } from "@/lib/utils"


type ImagePosition = {
    vertical?: "top" | "center" | "bottom" | "stretch";
    horizontal?: "left" | "center" | "right" | "stretch";
};

interface TileBodyProps {
    imagePosition?: ImagePosition;
    children?: React.ReactNode;
    withBoxShadow?: boolean;
}

interface TileImageContainerProps {
    imagePosition?: ImagePosition;
    withBoxShadow?: boolean;
    children?: React.ReactNode;
    isMobile?: boolean;
}

const TileBody: React.FC<TileBodyProps> = ({
    imagePosition,
    children,
    withBoxShadow
}) => {
    // Default classes for flex and height
    let classes = "flex flex-col grow min-h-[400px] ";

    // Conditional classes for justify-content
    if (
        !imagePosition ||
        imagePosition.vertical === "center" ||
        imagePosition.vertical === undefined
    ) {
        classes += "justify-center ";
    } else {
        classes += "justify-end ";
    }

    // Conditional classes for align-items
    if (
        !imagePosition ||
        imagePosition.horizontal === "center" ||
        imagePosition.horizontal === undefined
    ) {
        classes += "items-center ";
    } else {
        classes += "items-end ";
    }

    const topPadding = imagePosition?.vertical === "top" || imagePosition?.vertical === "stretch" ? "" : "pt-7";
    const bottomPadding = imagePosition?.vertical === "bottom" || imagePosition?.vertical === "stretch" ? "" : "pb-7";
    const leftPadding = imagePosition?.horizontal === "left" || imagePosition?.horizontal === "stretch" ? "" : "pl-7";
    const rightPadding = imagePosition?.horizontal === "right" || imagePosition?.horizontal === "stretch" ? "" : "pr-7";

    classes +=  withBoxShadow ? `${topPadding} ${bottomPadding} ${leftPadding} ${rightPadding}` : `${topPadding}`;
 

    return <div className={classes}>{children}</div>;
};

const TileImageContainer: React.FC<TileImageContainerProps> = ({
    imagePosition,
    withBoxShadow,
    children,
}) => {

    const topLeftRadius = (imagePosition?.vertical === "bottom" || imagePosition?.vertical === "center") && (imagePosition?.horizontal === "right" || imagePosition?.horizontal === "center") ? "rounded-tl-lg" : "";
    const topRightRadius = (imagePosition?.vertical === "bottom" || imagePosition?.vertical === "center") && (imagePosition?.horizontal === "left" || imagePosition?.horizontal === "center") ? "rounded-tr-lg" : "";
    const bottomLeftRadius = (imagePosition?.vertical === "top" || imagePosition?.vertical === "center") && (imagePosition?.horizontal === "right" || imagePosition?.horizontal === "center") ? "rounded-bl-lg" : "";
    const bottomRightRadius = (imagePosition?.vertical === "top" || imagePosition?.vertical === "center") && (imagePosition?.horizontal === "left" || imagePosition?.horizontal === "center") ? "rounded-br-lg" : "";

    const borderRadiusClasses = `${topLeftRadius} ${topRightRadius} ${bottomLeftRadius} ${bottomRightRadius}`

    const boxShadowClasses = withBoxShadow
        ? "shadow-[0_60px_60px_rgba(0,0,0,0.02),0_41.7776px_33.4221px_rgba(0,0,0,0.02),0_22.3363px_17.869px_rgba(0,0,0,0.03),0_12.5216px_10.0172px_rgba(0,0,0,0.03),0_6.6501px_5.32008px_rgba(0,0,0,0.04),0_2.76726px_2.21381px_rgba(0,0,0,0.07)]"
        : "";
        
    // Determine overflow classes
    const overflowClasses = withBoxShadow ? "overflow-hidden" : "overflow-visible";

    return (
        <div
            className={`h-full w-full max-w-full max-h-full notmobile:h-auto  ${borderRadiusClasses} ${boxShadowClasses} ${overflowClasses}`}
        >
            {children}
        </div>
    );
};

interface TileImageProps {
    imagePosition?: ImagePosition;
    withBoxShadow?: boolean;
    image: string | React.ReactElement;
    imageClassname?: string;
    mobileImage?: string | React.ReactElement;
}

function TileImage({ image, imagePosition, withBoxShadow, mobileImage, imageClassname }: TileImageProps) {

  

    // const isMobile = useIsMobile();

    return (
        // domLoaded  ? 
            <TileBody imagePosition={imagePosition} 
            //isMobile={isMobile} 
            withBoxShadow={withBoxShadow}>
            <TileImageContainer imagePosition={imagePosition} withBoxShadow={withBoxShadow} 
            //isMobile={isMobile}
            >

                {image && typeof image === "string" ? (
                    <div style={{ display: 'flex', flexDirection: 'column' }} 
                    //  className={`${ disMobile && 'h-full'}`}
                    className="relative h-full max-h-full notmobile:h-auto "
                    > 
                        <Image
                            alt="Mountains"
                    
                            className={cn(`
                            h-full
                            max-h-full 
                            w-auto
                            max-w-full 
                            object-cover 
                            object-left-top 
                            notmobile:w-full
                            notmobile:object-contain
                            notmobile:object-center
                            `, imageClassname)}
                            src={image}
                            sizes="100vh"
                            quality={100}
                            width={1000}
                            height={1000}
                            loading='eager'

                        />
                    </div>
                ) : image}
            </TileImageContainer>
        </TileBody>
        // :
        // <></>
    
    );
}



export default TileImage;
