'use client'
import Testimonial from '@/components/marketing/testimonial'
import { useTranslations } from 'next-intl';
import TestimonialImg01 from '@/../public/testimonials/testimonial-01.jpg'
import TestimonialImg02 from '@/../public/testimonials/testimonial-02.jpg'
import TestimonialImg03 from '@/../public/testimonials/testimonial-03.jpg'
import TestimonialImg04 from '@/../public/testimonials/testimonial-04.jpg'
import TestimonialImg05 from '@/../public/testimonials/testimonial-05.jpg'
import TestimonialImg06 from '@/../public/testimonials/testimonial-06.jpg'
import TestimonialImg07 from '@/../public/testimonials/testimonial-07.jpg'
import TestimonialImg08 from '@/../public/testimonials/testimonial-08.jpg'

export default function Testimonials() {

  const t = useTranslations('home.testimonials');

  const testimonials01 = [
    {
      image: TestimonialImg01,
      name: t("testimonials01.0.name"),
      user: t("testimonials01.0.user"),
      link: "#0",
      content: t("testimonials01.0.content")
    },
    {
      image: TestimonialImg02,
      name: t("testimonials01.1.name"),
      user: t("testimonials01.1.user"),
      link: "#0", 
      content: t("testimonials01.1.content")
    },
    {
      image: TestimonialImg03,
      name: t("testimonials01.2.name"),
      user: t("testimonials01.2.user"),
      link: "#0",
      content: t("testimonials01.2.content")
    },
    {
      image: TestimonialImg04,
      name: t("testimonials01.3.name"),
      user: t("testimonials01.3.user"),
      link: "#0",
      content: t("testimonials01.3.content") 
    }
  ];

  const testimonials02 = [
    {
      image: TestimonialImg05,
      name: t("testimonials02.0.name"),
      user: t("testimonials02.0.user"),
      link: "#0",
      content: t("testimonials02.0.content")
    },
    {
      image: TestimonialImg06,
      name: t("testimonials02.1.name"),
      user: t("testimonials02.1.user"),
      link: "#0",
      content: t("testimonials02.1.content") 
    },
    {
      image: TestimonialImg07,
      name: t("testimonials02.2.name"),
      user: t("testimonials02.2.user"),
      link: "#0",
      content: t("testimonials02.2.content")
    },
    { 
      image: TestimonialImg08,
      name: t("testimonials02.3.name"), 
      user: t("testimonials02.3.user"),
      link: "#0", 
      content: t("testimonials02.3.content")
    }
  ];


  return (
    <section className="bg-background">
      <div className="py-20 md:py-28">
        <div className="mx-auto max-w-6xl px-4 sm:px-6">
          <div className="mx-auto max-w-3xl pb-12 text-center md:pb-20">
            <h2 className="bg-gradient-to-r from-neutral-800/60 via-neutral-800 to-neutral-800/60 bg-clip-text text-xl font-semibold uppercase leading-tight tracking-widest text-transparent lg:text-2xl">{t("title")}</h2>
          </div>
        </div>
        <div className="mx-auto max-w-[94rem] space-y-6">
          {/* Row #1 */}
          <div className="group inline-flex w-full flex-nowrap overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_black_28%,_black_calc(100%-28%),transparent_100%)]">
            <div className="flex animate-infinite-scroll items-start justify-center group-hover:[animation-play-state:paused] md:justify-start [&>div]:mx-3">
              {/* Items */}
              {testimonials01.map((testimonial, index) => (
                <Testimonial key={index} testimonial={testimonial}>
                  {testimonial.content}
                </Testimonial>
              ))}
            </div>
            {/* Duplicated element for infinite scroll */}
            <div className="flex animate-infinite-scroll items-start justify-center group-hover:[animation-play-state:paused] md:justify-start [&>div]:mx-3" >
              {/* Items */}
              {testimonials01.map((testimonial, index) => (
                <Testimonial key={index} testimonial={testimonial}>
                  {testimonial.content}
                </Testimonial>
              ))}
            </div>
          </div>
          {/* Row #2 */}
          <div className="group inline-flex w-full flex-nowrap overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_black_28%,_black_calc(100%-28%),transparent_100%)]">
            <div className="flex animate-infinite-scroll-inverse items-start justify-center [animation-delay:-7.5s] group-hover:[animation-play-state:paused] md:justify-start [&>div]:mx-3">
              {/* Items */}
              {testimonials02.map((testimonial, index) => (
                <Testimonial key={index} testimonial={testimonial}>
                  {testimonial.content}
                </Testimonial>
              ))}
            </div>
            {/* Duplicated element for infinite scroll */}
            <div className="flex animate-infinite-scroll-inverse items-start justify-center [animation-delay:-7.5s] group-hover:[animation-play-state:paused] md:justify-start [&>div]:mx-3">
              {/* Items */}
              {testimonials02.map((testimonial, index) => (
                <Testimonial key={index} testimonial={testimonial}>
                  {testimonial.content}
                </Testimonial>
              ))}
            </div>
          </div >
        </div >
      </div >
    </section >
  )
}