import { cn } from "@/lib/utils"

interface BadgeTitleProps {
    text: string;
    Icon: React.ElementType | undefined;
    iconColor?: string; // Color for the icon
    gradientFrom?: string; // Starting color of the text gradient
    gradientTo?: string; // Ending color of the text gradient
    className?: string;
  }
  
// Define the BadgeTitle functional component
export const BadgeTitle: React.FC<BadgeTitleProps> = ({ text, Icon, iconColor, gradientFrom, gradientTo, className }) => {
    return (
      <div className={cn("mx-auto my-0 flex max-w-7xl items-center justify-center px-11 pb-3 pt-16 lg:px-16 lg:pb-4 lg:pt-24", className)}>
        <div className="mb-[22px] inline-flex items-center justify-center overflow-hidden rounded-full border border-slate-400/10 bg-slate-400/5 px-4 py-2 text-xs uppercase leading-[1.33] tracking-[3px] md:px-5 md:py-[5px] md:leading-[2] lg:mb-5 lg:text-xs">
          {Icon && <Icon className={`mr-2 size-5 ${!iconColor && 'text-primary-600'}`} style={{ color: iconColor }} /> }
          <span className={`bg-gradient-to-r bg-clip-text font-medium text-transparent  ${!gradientFrom && 'from-primary-800'} ${!gradientTo && 'to-primary'}`} style={{ backgroundImage: `linear-gradient(to right, ${gradientFrom}, ${gradientTo})` }}>
            {text}
          </span>
        </div>
      </div>
    );
  };
  