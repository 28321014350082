import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["TileFeatures"] */ "/vercel/path0/src/components/marketing/copilot/copilot-tile-features.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LinkedinVideo"] */ "/vercel/path0/src/components/marketing/copilot/linkedin-video.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/marketing/hero.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/marketing/spotlight.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/marketing/testimonials.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Grid","TileBlock"] */ "/vercel/path0/src/components/marketing/tile-block.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SiteHeader"] */ "/vercel/path0/src/components/navigation/site-header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Accordion","AccordionItem","AccordionTrigger","AccordionContent"] */ "/vercel/path0/src/components/ui/accordion.tsx");
