import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"
import Link from "next/link" // Import the Link component from Next.js

import { cn } from "@/lib/utils"

const ctaVariants = cva(
  "inline-flex items-center justify-center border select-none rounded-full text-base font-semibold transition ease-in-out duration-200 group",
  {
    variants: {
      variant: {
        default:
          "text-black bg-white hover:bg-black/[0.02] focus-visible:ring-white/30 focus-visible:bg-white/90 disabled:hover:bg-white",
        outline:
          "backdrop-blur-xl text-slate-11 bg-black-100/0 hover:bg-black/[0.02] hover:text-slate-12 focus-visible:ring-slate-7 focus-visible:bg-slate-6 disabled:hover:bg-slate-1",
        primary:
          "text-white bg-primary hover:bg-primary/90 focus-visible:ring-primary/30 focus-visible:bg-primary/90 disabled:hover:bg-primary/90",
      },
      size: {
        default: "h-12 pl-5 pr-2 gap-0",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof ctaVariants> {
  asChild?: boolean
  isLink?: boolean
  href?: string
}

const ArrowIcon = () => (
    <span className="text-[#70757E] group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out">
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.75 8.75L14.25 12L10.75 15.25"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </svg>
    </span>
  );


  const WhiteArrowIcon = () => (
    <span className="text-white group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out">
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.75 8.75L14.25 12L10.75 15.25"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </svg>
    </span>
  );
  


const CtaButton = React.forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(
    ({ className, variant, size, asChild = false, isLink = false, href, ...props }, ref) => {
      const Comp : React.ElementType = asChild ? Slot : isLink ? Link : "button"

      let adjustedRef: React.LegacyRef<HTMLButtonElement> | undefined = ref as React.LegacyRef<HTMLButtonElement>;
  
    return (
      <Comp
        className={cn(ctaVariants({ variant, size, className }))}
        // ref={isLink ? undefined : ref}
        ref={!isLink ? adjustedRef : undefined} // Apply the ref only when not rendering a Link
        {...(isLink ? { href } : {})}
        {...props}
      >
        {props.children}
        {variant === "primary" ? <WhiteArrowIcon/> : <ArrowIcon/>}
      </Comp>
    )
  }
)

CtaButton.displayName = "CtaButton"

export { CtaButton, ctaVariants }

// import * as React from "react"
// import { Slot } from "@radix-ui/react-slot"
// import { cva, type VariantProps } from "class-variance-authority"


// import { cn } from "@/lib/utils"

// const ctaVariants = cva(
//   "inline-flex items-center justify-center border select-none rounded-full text-base font-semibold transition ease-in-out duration-200 ",
//   {
//     variants: {
//       variant: {
//         default:
//           "text-black bg-white hover:bg-black/[0.02] focus-visible:ring-white/30 focus-visible:bg-white/90 disabled:hover:bg-white",
//         outline:
//           "backdrop-blur-xl text-slate-11 bg-black-100/0 hover:bg-black/[0.02] hover:text-slate-12 focus-visible:ring-slate-7 focus-visible:bg-slate-6 disabled:hover:bg-slate-1",
//       },
//       size: {
//         default: "h-12 pl-5 pr-2 gap-0",
//       },
//     },
//     defaultVariants: {
//       variant: "default",
//       size: "default",
//     },
//   }
// )

// export interface ButtonProps
//   extends React.ButtonHTMLAttributes<HTMLButtonElement>,
//     VariantProps<typeof ctaVariants> {
//   asChild?: boolean
// }

// const CtaButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
//   ({ className, variant, size, asChild = false, ...props }, ref) => {
//     const Comp = asChild ? Slot : "button"
//     return (
//       <Comp
//         className={cn(ctaVariants({ variant, size, className }))}
//         // href={href}
//         ref={ref}
//         {...props}
//       >
//         {props.children}
//         <span className="text-[#70757E]">
//           <svg
//             fill="none"
//             height="24"
//             viewBox="0 0 24 24"
//             width="24"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path
//               d="M10.75 8.75L14.25 12L10.75 15.25"
//               stroke="currentColor"
//               strokeLinecap="round"
//               strokeLinejoin="round"
//               strokeWidth="1.5"
//             ></path>
//           </svg>
//         </span>
//       </Comp>
//     )
//   }
// )
// CtaButton.displayName = "CtaButton"

// export { CtaButton, ctaVariants }