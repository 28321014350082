import React from 'react';
import Spotlight from "@/components/marketing/spotlight";
import { Grid, TileBlock , type TileBlockProps} from "@/components/marketing/tile-block";

export interface TilesSectionProps {
  id?: string;
  blocks: Block[];
  mainColor: string;
  title?: string | React.ReactElement;
}

interface Block extends TileBlockProps {
  breakpoints: {
    lg: number;
    md: number;
    sm: number;
    xs: number;
  };
  spotlight?: boolean;
  style?: React.CSSProperties;
}

export function TilesSection({ blocks, mainColor, title, id }: TilesSectionProps) {


  return (
    <section id={id}>

      {
          typeof title === "string" ?          <div className="mx-auto my-0 max-w-7xl px-11 pb-7 pt-16 lg:px-16 lg:pb-10 lg:pt-24" >
     
          <h2 className={`${mainColor} text-4xl font-extralight uppercase tracking-[.3em] lg:text-5xl`}>{title}</h2>
        </div> : title
      }

      <div className="mx-auto my-0 max-w-7xl">
        <Spotlight className="group grid grid-cols-12 gap-4 sm:gap-5 lg:gap-6">
         
          {blocks.map((block, index) => (
          

            
            <Grid
              key={index}
              lg={block.breakpoints.lg}
              md={block.breakpoints.md}
              sm={block.breakpoints.sm}
              xs={block.breakpoints.xs}
              spotlight={block.spotlight}
              style={block.style}
            >
              <TileBlock
                allFontsWhite={block.allFontsWhite}
                badge={block.badge}
                backgroundImage={block.backgroundImage}
                backgroundImageClassname={block.backgroundImageClassname}
                background={block.background}
                className={block.className}
                description={block.description}
                descriptionStyle={block.descriptionStyle}
                icon={block.icon}
                imagePosition={block.imagePosition}
                image={block.image}
                imageClassname={block.imageClassname}
                mobileImage={block.mobileImage}
                quote={block.quote}
                style={block.style}
                titleColor={mainColor}
                title={block.title}
                titleStyle={block.titleStyle}
                variant={block.variant}
                withBoxShadow={block.withBoxShadow}
                
              />
            </Grid>
          ))}
        </Spotlight>
      </div>
    </section>
  );
}