"use client"

import Image from "next/image"
import { useIsMobile } from "@/hooks"
import { BadgeTitle } from "@/components/marketing/badge-title"

import { Parallax } from "react-scroll-parallax"
import { ShimmerButton } from "@/components/marketing/copilot/shimmer-button"
import {
  TilesSection,
  type TilesSectionProps,
} from "@/components/marketing/tiles-section"
import { Lightbulb, CalendarCheck, Ban } from "lucide-react"
import { useTranslations } from 'next-intl';

interface ResponsiveProps {
  image: string
  alt?: string
  sizes?: string
  style?: React.CSSProperties
}

const Responsive: React.FC<ResponsiveProps> = ({
  image,
  alt,
  sizes,
  style,
}) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", ...style }}>
      <Image
        alt={alt || "Responsive Image"}
        src={image}
        sizes={sizes ? sizes : "100vw"}
        width={1000}
        height={1000}
        style={{
          width: "100%",
          height: "auto",
        }}
      />
    </div>
  )
}

export function TileFeatures() {
  const t = useTranslations('home.features');
  const isMobile = useIsMobile()
  const sections: TilesSectionProps[] = [
    {
      title: <BadgeTitle text={t('title')} Icon={Lightbulb} iconColor={"#0073b1"} gradientFrom={"#0073b1"} gradientTo={"#0073b1"}/>,
      id: "solution",
      mainColor: "text-[#0073b1]",
      blocks: [
        {
          title: t('blocks.0.title'),
          variant: "image",
          breakpoints: {
            lg: 12,
            md: 12,
            sm: 12,
            xs: 12,
          },
          image: (
            <div className="grid w-full grid-cols-12 gap-4 sm:gap-5 lg:gap-6">
              <div className="relative col-span-12  size-full md:h-[550px] lg:col-span-6">
                <Image
                  src={"/tiles/sales-copilot/linkedin-chat1.png"}
                  alt="LinkedIn Chat 1"
                  quality={100}
                  width={1000}
                  height={1000}
                  className="object-contain size-full translate-y-4 scale-110 object-contain sm:translate-y-0 sm:scale-100"
                />

                <Parallax
                  className="absolute left-0 top-0 z-10 size-full translate-y-4 scale-110 object-contain sm:translate-y-0 sm:scale-100"
                  scale={isMobile ? [1, 1] : [1.4, 1]}
                  rotate={isMobile ? [0, 0] : [10, -10]}
                  translateY={isMobile ? [0, 0] : [10, -10]}
                >
                  <Image
                    src={"/tiles/sales-copilot/wand-1.png"}
                    alt="Magic Wand 1"
                    quality={100}
                    width={1000}
                    height={1000}
                  />
                </Parallax>
              </div>
              <div className="relative col-span-12 size-full md:h-[550px] lg:col-span-6">
                <Image
                  src={"/tiles/sales-copilot/linkedin-chat2.png"}
                  alt="LinkedIn Chat 2"
                  quality={100}
                  width={1000}
                  height={1000}
                  className="size-full translate-y-4 scale-110 object-contain sm:translate-y-0 sm:scale-100"
                />
                <Parallax
                  className="absolute left-0 top-0 z-10 size-full translate-y-4 scale-110 object-contain sm:translate-y-0 sm:scale-100"
                  scale={isMobile ? [1, 1] : [1.4, 1]}
                  translateY={isMobile ? [0, 0] : [10, -10]}
                  rotate={isMobile ? [0, 0] : [10, -10]}
                >
                  <Image
                    src={"/tiles/sales-copilot/wand-2.png"}
                    alt="Magic Wand 2"
                    quality={100}
                    width={1000}
                    height={1000}
                    className="absolute z-10 top-0 left-0 size-full translate-y-4 scale-110 object-contain sm:translate-y-0 sm:scale-100"
                  />
                </Parallax>
              </div>
            </div>
          ),
          withBoxShadow: false,
          spotlight: true,
          description: t('blocks.0.description'),
          imagePosition: { vertical: "bottom", horizontal: "center" },
          imageClassname: "h-[500px]",
        },

        {
          title: t('blocks.1.title'),
          variant: "image",
          breakpoints: {
            lg: 8,
            md: 8,
            sm: 8,
            xs: 12,
          },
          image: "/tiles/sales-copilot/linkedin-ui.png",
          spotlight: true,
          description: t('blocks.1.description'),
          imagePosition: { vertical: "bottom", horizontal: "center" },
          imageClassname: "object-contain notmobile:object-scale-down px-6 transform translate-y-6",
          style: { maxHeight: "700px" },
        },

        {
          title: t('blocks.2.title'),
          variant: "image",
          breakpoints: {
            lg: 4,
            md: 4,
            sm: 4,
            xs: 12,
          },
          image: (
            <div className="relative h-[500px] w-full">
              <div className="absolute size-full overflow-hidden">
                <Parallax
                  className="absolute left-0 top-0 h-[200%] w-full"
                  translateY={isMobile ? [0, 0] : ["80%", "-140%"]}
                >
                  <Image
                    src={"/tiles/sales-copilot/bulk-send.png"}
                    alt="Bulk Send"
                    quality={100}
                    width={1000}
                    height={1000}
                    className="absolute left-0 top-0 translate-y-4 object-cover"
                  />
                </Parallax>

              </div>
              <div className="absolute left-1/2 -translate-x-1/2">
                <ShimmerButton />
              </div>
            </div>
          ),
          spotlight: true,
          description: t('blocks.2.description'),
          imagePosition: { vertical: "bottom", horizontal: "center" },
          style: { maxHeight: "700px" },
        },
        
        {
          title: t('blocks.3.title'),
          icon: <CalendarCheck/>,
          variant: "quote",
          backgroundImage: "/tiles/abstract-background-8.jpg",
          description: t('blocks.3.description'),
          allFontsWhite: true,
          breakpoints: {
            lg: 4,
            md: 4,
            sm: 4,
            xs: 12,
          },
          quote: t('blocks.3.quote'),
          style: { maxHeight: "700px" },
        },

        {
          title: t('blocks.4.title'),
          variant: "image",
          breakpoints: {
            lg: 8,
            md: 8,
            sm: 8,
            xs: 12,
          },
          image: "/tiles/sales-copilot/linkedin-ban.png",
          spotlight: true,
          description: t('blocks.4.description'),
          imagePosition: { vertical: "center", horizontal: "center" },
          imageClassname: "object-contain notmobile:object-scale-down max-h-[500px]",
          style: { maxHeight: "700px" },
        },

      ],
    },
  ]

  return (
    <>
      {sections.map((section, index) => (
        <TilesSection key={index} {...section} />
      ))}
    </>
  )
}